<template>
  <div id="statistic-analysis">
    <div class="tree">
      <div class="tree-title">组织结构</div>
      <el-input placeholder="输入检索内容" suffix-icon="el-icon-search" v-model.trim="filterText"></el-input>
      <el-tree ref="tree" v-loading="loading" :default-expand-all="false" :show-checkbox="false" :data="treeData"
        :check-on-click-node="false" :props="defaultProps" node-key="id" :filter-node-method="filterNode"
        @node-click="handleCheck"></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>能源管理</span>
      </div>
      <div class="query-form">
        <div class="newView">
          <div class="day" v-for="(item, index) in tabList" :key="index" :class="select == item.value ? 'day1' : ''"
            @click="dayFn(item)">{{ item.name }}</div>
        </div>
        <el-form class="form" ref="form" :inline="true">
          <el-form-item label="场站名称">
            <el-select filterable  v-model="requestParam.stationNumber" :popper-append-to-body="false" clearable
              placeholder="请选择场站">
              <el-option v-for="item in stationData" :key="item.id" :label="item.stationName +' '+item.stationNumber"
                :value="item.stationNumber"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间" class="query-date">
            <el-date-picker v-show="dateType=='date'" type="date" :append-to-body="false" placeholder="选择日期" value-format="yyyy-MM-dd" class="custom-input "
              v-model="requestParam.time" style="width: 100%;"></el-date-picker>
            <el-date-picker v-show="dateType=='month'" type="month" :append-to-body="false" placeholder="选择日期" value-format="yyyy-MM-dd" class="custom-input "
              v-model="requestParam.time" style="width: 100%;"></el-date-picker>
            <el-date-picker v-show="dateType=='year'" type="year" :append-to-body="false" placeholder="选择日期" value-format="yyyy-MM-dd" class="custom-input "
              v-model="requestParam.time" style="width: 100%;"></el-date-picker>
          </el-form-item>
          <el-button @click="search" type="primary" icon="el-icon-search">查询</el-button>
        </el-form>
      </div>
      <div class="real-time-electricity">
        <div class="echarts-title">
          <img src="../../assets/images/titleT.png" alt="" />
          <div class="">总用电量</div>
        </div>
        <div id="nowElectricity"></div>
      </div>
      <div class="chart-content">
        <div class="contList">
          <div class="statistics">
            <div class="analysis">
              <div class="">
                <div class="echarts-title">
                  <img src="../../assets/images/titleT.png" alt="" />
                  <div class="">尖峰平谷</div>
                </div>
              </div>
              <div class="selectData">
              </div>
            </div>
            <div id="statisticsEcharts" class="statistics"></div>
          </div>
          <div class="statistics">
            <div class="analysis">
              <div class="">
                <div class="echarts-title">
                  <img src="../../assets/images/titleT.png" alt="" />
                  <div class="">碳排分析</div>
                </div>
              </div>
              <div class="selectData">
                <!-- <el-date-picker type="date" placeholder="日期">
                </el-date-picker> -->
              </div>
            </div>
            <div id="twoLineEcharts" class="statistics"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { deviceApi } from "@/api/device.js";
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
import { energyStationApi } from "@/api/energyStation.js";
export default {
  name: "Administration",
  data() {
    return {
      requestParam: {
        stationNumber: "",
        time: ''

      },
      loading: false,
      checkedNodes: [],
      defaultProps: {
        children: "companyInfoList",
        id: "id",
        label: "companyName",
      },
      treeData: [],
      stationData: [],
      filterText: "",
      currentDate: '',
      firstDay: '',
      tabList: [
        {
          name: '日',
          value: 1,
        },
        {
          name: '月',
          value: 3,
        },
        {
          name: '年',
          value: 5,
        },

      ],
      select: 1,
      staTime: '',
      endTime: '',
      dateType: 'date'
    };
  },
  created() {
    this.$store.commit("increment", "能源管理");
    this.$store.commit("selectChild", "");
    this.$store.commit("selectChildren", "");
    this.getDate()
  },
  mounted() {

    this.queryUnitName();
    this.drawNowElectricity()
    this.twoLine()
    this.getChartCake()
  },
  watch: {
    filterText(val) {
      console.log('11111111111111', val);
      this.$refs.tree.filter(val);
    },
  },

  methods: {
    // 查询
    search() {
      this.drawNowElectricity()
      this.twoLine()
      this.getChartCake()



    },
    //table切换
    dayFn(item) {
      this.select = item.value
      this.drawNowElectricity()
      this.twoLine()
      this.getChartCake()
      if (item.value == 1) {
        this.dateType = 'date'
      } else if (item.value == 3) {
        this.dateType = 'month'
      } else {
        this.dateType = 'year'
      }
    },
    // 获取当年
    getDate() {
      let date = new Date(); // 获取当前时间
      const year = date.getFullYear(); // 获取当前年份
      const currentMonth = date.getMonth() + 1; // 获取当前月份
      const currentDay = date.getDate(); //获取当前日
      let month = currentMonth < 10 ? `0${currentMonth}` : currentMonth; // 获取当前月份
      let day = currentDay < 10 ? `0${currentDay}` : currentDay; //获取当前日
      this.firstDay = `${year}-01-01`;
      this.currentDate = `${year}-${month}-${day}`;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
    handleCheck(checkedNodes, event) {
      if (this.checkedNodes.id == checkedNodes.id) {
        this.checkedNodes=''
        const currentNode = this.$refs.tree.$el.querySelector('.is-current');
          if (currentNode) {
            currentNode.classList.remove('is-current');
          } 
      }else{
        this.checkedNodes = checkedNodes;

      }
      this.queryStation(this.checkedNodes.id);
    },
    queryUnitName() {
      this.loading = true;
      deviceApi.queryUnitName().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.treeData = res.data;
          this.stationData = []

          // this.drawNowElectricity()
          // this.drawNowElectricity()
          // this.getChartCake()
          
          this.queryStation();
          // this.queryStation(res.data[0].id);
          // this.$nextTick(() => {
          //   this.$refs.tree.setCurrentKey(this.treeData[0].id); // 默认选中节点第一个
          // });
        }
      });
    },
    queryStation(companyId) {
      energyStationApi.queryStation({companyId:companyId}).then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
          this.requestParam.stationNumber = ''
          if (res.data.length !== 0) {
            this.requestParam.stationNumber = res.data[0].stationNumber;
            this.drawNowElectricity()
            this.twoLine()
            this.getChartCake()
          }

        }
      });
    },
    //总用电量
    drawNowElectricity() {
      let type;
      if (this.select == 1) {
        this.getDay()
      } else if (this.select == 3) {
        this.getYue(this.requestParam.time)
      } else if (this.select == 5) {
        this.getYear(this.requestParam.time)
      }
      let obj = {
        stationNumber: this.requestParam.stationNumber,
        type: this.select,
        staTime: this.staTime,
        endTime: this.endTime

      }
      if (this.requestParam.time && this.select == 1) {
        obj.staTime = this.requestParam.time
        obj.endTime = this.requestParam.time
      }

      emissionsCarbonApi.statisticsApi(obj).then(res => {
        let dataX = []
        let dataSer = []
        res.data.forEach(item => {
          dataSer.push(item.dianNeng)
          if (this.select == 1) {

            dataX.push(item.ts.slice(11, 16))
          } else {
            dataX.push(item.ts.slice(0, 10))
          }
          // let time =item.ts.slice(11,16)
          // console.log(time);
        })
        const nowElectricity = echarts.init(document.getElementById("nowElectricity"));
        var option = {
          title: {
            text: "单位:KWh",
            left: 10,
            textStyle: {
              color: "#aed6ff"
            }
          },

          legend: {
            data: ["电能"],
            right: '31px',
            top: '16px',
            textStyle: {
              color: "#AED6FF"
            }
          },
          toolbox: {
            show: false,
            feature: {
              dataZoom: {
                yAxisIndex: false
              },
              saveAsImage: {
                pixelRatio: 2
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            bottom: 90,
            left: 100,
            right: '3%'
          },
          dataZoom: [
            {
              type: 'inside'
            },
            {
              type: 'slider'
            }
          ],
          xAxis: {
            data: dataX,
            silent: false,
            splitLine: {
              show: false
            },
            splitArea: {
              show: false
            },
            axisLabel: {
              // interval: 30, 
              textStyle: {
                color: '#aed6ff' // 设置 x 轴标签字体颜色
              }
            }
          },
          yAxis: {
            splitArea: {
              show: false
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',//
                color: "#0b2159"
              },
            },
            axisLabel: {
              color: '#aed6ff' // 
            }
          },
          legend: {
            data: ["电能"],
            right: '31px',
            top: '16px',
            textStyle: {
              color: "#AED6FF"
            }
          },
          backgroundColor: "#071B41",
          series: [
            {
              name: "电能",
              barMaxWidth: 10, // 最大宽度
              type: 'bar',
              data: dataSer,
              // Set `large` for large data amount
              large: true,
              itemStyle: {
                normal: {
                  color: '#E0AB34' // 设置柱子颜色为蓝色
                }
              }

            }
          ]
        };

        nowElectricity.setOption(option);
      })


    },
    //尖峰平谷
    getChartCake() {
      let obj = {}
      if (this.select == 1) {
        this.getDay()
        if (this.requestParam.time) {
          obj = {
            stationNumber: this.requestParam.stationNumber,
            dayStart: this.requestParam.time,
            dayEnd: this.requestParam.time,
          }
        } else {
          obj = {
            stationNumber: this.requestParam.stationNumber,
            dayStart: this.staTime,
            dayEnd: this.endTime,
          }
        }
      } else if (this.select == 3) {
        this.getYue(this.requestParam.time)
        let month = this.staTime.split("-")
        let month1 = month[1] > 10 ? month[1] : '0' + month[1]
        obj = {
          stationNumber: this.requestParam.stationNumber,
          month: month[0] + '-' + month1,
        }
      } else if (this.select == 5) {
        this.getYear(this.requestParam.time)
        obj = {
          stationNumber: this.requestParam.stationNumber,
          year: this.staTime.substring(0, 4),

        }
      }
      // obj.stationNumber = 'ZD0973-01'

      console.log(obj);
      emissionsCarbonApi.getChartCake(obj).then(res => {
        if (res.code === 200) {
          let xAxisData;
          if (this.select != 5) {
            xAxisData = res.data.peakPower.map((item) => {
              let day;
              if (item.nowTime) {
                day = item.nowTime.slice(8, 10) + "日";
              }
              return this.select == 1 ? obj.dayStart : day;
            });
          } else {
            let chartList = this.transformArray(res.data.peakPower)
            xAxisData = chartList.map(item => {
              return item.nowTime.substring(5, 8)
            })
          }
          // 尖
          let sharpSeries;
          if( res.data.tipPower){
            if (this.select != 5  ) {
              sharpSeries = res.data.tipPower.map((item) => {
                return this.select == 1 ? item : item.onceEpaddIns;
              });
            } else {
              let chartList = this.transformArray(res.data.tipPower)
              sharpSeries = chartList.map(item => {
                return item.onceEpaddIns
              })
            }
          }
          // 峰
          let peakSeries
          if (this.select != 5) {
            peakSeries = res.data.peakPower.map((item) => {
              return this.select == 1 ? item : item.onceEpaddIns;
            });
          } else {
            let chartList = this.transformArray(res.data.peakPower)
            peakSeries = chartList.map(item => {
              return item.onceEpaddIns
            })
          }
          // 平
          let averageSeries;
          if (this.select != 5) {
            averageSeries = res.data.flatPower.map((item) => {
              return this.select == 1 ? item : item.onceEpaddIns;
            });
          } else {
            let chartList = this.transformArray(res.data.flatPower)
            averageSeries = chartList.map(item => {
              return item.onceEpaddIns
            })
          }
          // 谷
          let valleySeries;
          if (this.select != 5) {
            valleySeries = res.data.valleyPower.map((item) => {
              return this.select == 1 ? item : item.onceEpaddIns;
            })
          } else {
            let chartList = this.transformArray(res.data.valleyPower)
            valleySeries = chartList.map(item => {
              return item.onceEpaddIns
            })
          }
          this.drawConsumption(
            xAxisData,
            sharpSeries,
            peakSeries,
            averageSeries,
            valleySeries
          );
        }
      })
    },
    //尖峰平谷
    drawConsumption(xAxisData, sharpSeries, peakSeries, averageSeries, valleySeries) {
      // if (myEcharts) {
      //   myEcharts.dispose(); // 销毁已存在的图表实例
      //   console.log("~~~~~~~~~~~~~~~~~~~");
      // }
      var myEcharts = 
      echarts.init(document.getElementById("statisticsEcharts"))
     
      var option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          icon: "square",
          itemWidth: 14,
          itemHeight: 14,
          right: "15%",
          top: "2px",
          textStyle: {
            color: "#C5D8FF",
            fontSize: 14
          },
        },
        xAxis: {
          data: xAxisData,
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
              fontSize: 16
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true, //是否显示轴线
            lineStyle: {
              color: "#1B3475", //刻度线的颜色
            },
          },
        },
        yAxis: {
          name: "电量:km/h",
          nameTextStyle: {
            color: "#C5D8FF",
          },
          // data: [0,1000,2000,3000,4000,5000,6000],
          axisLabel: {
            textStyle: {
              color: "#C5D8FF", // 设置字体颜色
            },
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "尖电量",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#FF6A24" },
                { offset: 1, color: "#FF8C56" },
              ]),
            },
            data: sharpSeries,
          },
          {
            name: "峰电量",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#CEA500" },
                { offset: 1, color: "#EDC42F" },
              ]),
            },
            data: peakSeries,
          },
          {
            name: "平电量",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#3DE2BC" },
                { offset: 1, color: "#3DE2BC" },
              ]),
            },
            data: averageSeries,
          },
          {
            name: "谷电量",
            type: "bar",
            stack: "Search Engine",
            emphasis: {
              focus: "series",
            },
            barWidth: 10,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#4683FF" },
                { offset: 1, color: "#2D54A4" },
              ]),
            },
            data: valleySeries,
          },
        ],
      };
      myEcharts.setOption({
                    dataZoom: [
                        {
                            type: 'slider',    // 数据缩放类型为滑动条
                            start: 0,          // 开始位置（范围为 0 到 100）
                            end: 100            // 结束位置（范围为 0 到 100）
                        },
                        {
                            type: 'inside',    // 内置的数据缩放类型，鼠标滚轮缩放
                            start: 0,          // 开始位置（范围为 0 到 100）
                            end: 100            // 结束位置（范围为 0 到 100）
                        }
                        ]
                    });
    

      myEcharts.setOption(option);
    },
    transformArray(originalArray) {
      return originalArray.map(function (item) {
        // 获取原始对象的键和值
        var key = Object.keys(item)[0];
        var value = item[key];

        // 构建新的对象
        return {
          nowTime: key,
          onceEpaddIns: value
        };
      });
    },
    //总碳排



    twoLine() {
      let obj = {
        stationNumber: this.requestParam.stationNumber,
        time: this.requestParam.updateTime
      }
      if (!this.requestParam.updateTime) {
        var today = new Date();
        obj.time = today.getFullYear();
      } else {
        obj.time = this.requestParam.updateTime.substring(0, 4)
      }
      emissionsCarbonApi.analysisOneApi(obj).then(res => {
        if (res.data) {
          let now = []
          let last = []
          res.data.forEach(item => {
            now.push(item.nowTanPait)
            last.push(item.lastTanPait)

            var myEcharts = echarts.init(document.getElementById("twoLineEcharts"));
            var option = {
              grid: {
                left: "5%",
                right: "5%",
                containLabel: true,
              },
              tooltip: {},
              backgroundColor: "#071B41",
              legend: {
                data: ["去年", "今年"],
                right: "31px",
                top: "16px",
                textStyle: {
                  color: "#AED6FF",
                },
              },
              color: ["#2a65b4", " #2fc9cb"],
              xAxis: {
                boundaryGap: false,
                type: "category",
                data: [
                  "1月",
                  "2月",
                  "3月",
                  "4月",
                  "5月",
                  "6月",
                  "7月",
                  "8月",
                  "9月",
                  "10月",
                  "11月",
                  "12月",
                ],
                axisTick: {
                  alignWithLabel: true,
                },
                axisLabel: {
                  textStyle: {
                    color: "#aed6ff", // 设置 x 轴标签字体颜色
                  },
                },
              },
              yAxis: {
                type: "value",
                name: "碳排：t",
                nameTextStyle: {
                  color: "#aed6ff",
                },
                splitLine: {
                  lineStyle: {
                    type: "dashed", //
                    color: "#0b2159",
                  },
                },
                axisLabel: {
                  textStyle: {
                    color: "#aed6ff", //
                  },
                },
              },
              series: [
                {
                  data: last,
                  type: "line",
                  name: "去年",
                },
                {
                  data: now,
                  type: "line",
                  name: "今年",
                },
              ],
            };

            myEcharts.setOption(option);
          })
        }
      })


    },


    getYue(dateString) {
      let now;
      if (dateString) {
        now = new Date(dateString);
      } else {
        now = new Date();
      }
      // let now = new Date();
      let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      this.staTime = firstDay.getFullYear() + '-' + (firstDay.getMonth() + 1) + '-' + firstDay.getDate()
      this.endTime = lastDay.getFullYear() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getDate()
      console.log("这个月的第一天是：" + this.staTime);
      console.log("这个月的最后一天是：" + this.endTime);
    },
    getJI(dateString) {
      let now;
      if (dateString) {
        now = new Date(dateString);
      } else {
        now = new Date();
      }
      let startMonth = Math.floor(now.getMonth() / 3) * 3;
      let startDay = new Date(now.getFullYear(), startMonth, 1);
      let endMonth = Math.floor(now.getMonth() / 3) * 3 + 2;
      let endDay = new Date(now.getFullYear(), endMonth + 1, 0);
      this.staTime = startDay.getFullYear() + '-' + (startDay.getMonth() + 1).toString().padStart(2, '0') + '-' + startDay.getDate().toString().padStart(2, '0')
      this.endTime = endDay.getFullYear() + '-' + (endDay.getMonth() + 1).toString().padStart(2, '0') + '-' + endDay.getDate().toString().padStart(2, '0')
      console.log("季度的第一天是： " + this.staTime);
      console.log("季度的最后一天是： " + this.endTime);
    },
    getYear(dateString) {
      let now;
      if (dateString) {
        now = new Date(dateString);
      } else {
        now = new Date();
      }
      let firstDayOfYear = new Date(now.getFullYear(), 0, 1);
      let lastDayOfYear = new Date(now.getFullYear(), 12, 0);
      this.staTime = firstDayOfYear.getFullYear() + '-' + (firstDayOfYear.getMonth() + 1).toString().padStart(2, '0') + '-' + firstDayOfYear.getDate().toString().padStart(2, '0')
      this.endTime = lastDayOfYear.getFullYear() + '-' + (lastDayOfYear.getMonth() + 1).toString().padStart(2, '0') + '-' + lastDayOfYear.getDate().toString().padStart(2, '0')
      console.log("今年的第一天是： " + this.staTime);
      console.log("今年的最后一天是： " + this.endTime);
    },
    getDay() {
      var date = new Date();
      date.setDate(date.getDate());
      var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
      this.staTime = yesterday
      this.endTime = yesterday
      console.log(this.endTime);
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#statistic-analysis {
  width: 100%;
  min-height: 781px;
  font-size: 16px;
  display: flex;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

.main {
  width: calc(100% - 240px);
  background: url("../../assets/images/content-background.png") no-repeat center;
  background-size: cover;
  padding: 22px 0 26px 30px;
  box-sizing: border-box;
}

.real-time-electricity {
  padding: 16px;
  box-sizing: border-box;
  background: #071b42;
}

.chart-content {
  padding: 16px 0;
  box-sizing: border-box;
  background: #05132e;
}

.contList {
  display: flex;
  justify-content: space-between;
  background: #05132e;
}

#nowElectricity {
  width: 100%;
  height: 690px;
}

.mycharts {
  width: 100%;
  height: 260px;
}

.titleEcharts {
  text-align: center;
  width: 100%;
  font-size: 18px;
  padding-bottom: 10px;
}

.echarts-title {
  display: flex;
  align-items: center;
  margin: 10px 0 0 10px;
}

.echarts-title span {
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  font-size: 14px;

}

.analysis {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid #fff; */
}

.statistics {
  width: 49.5%;
  background: #071b42;
}




.statistics {
  width: 100%;
  height: 400px;
}

.newView {
  display: flex;
  margin-bottom: 10px;
}

.day {
  background-color: #041B44;
  /* background-color: #002C6C; */
  cursor: pointer;
  border-radius: 3px;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.day:hover {

  background-color: #002C6C;
  color: #ffc74a;
}

.day1 {
  background-color: #002C6C;
  color: #ffc74a;

}
</style>
